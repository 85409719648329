import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/About/About.scss';
import gradient from '../../assets/images/about-gradient.svg';

const About: React.FC = () => {
  const { t } = useTranslation('mainPage');

  return (
    <div className="goals-container">
      {((window.innerWidth <= 1030 && window.innerWidth > 950) || window.innerWidth < 555) && (
        <Typography className="goals-title h1-title">{t('goals_title')}</Typography>
      )}
      <div className="goals">
        <div className="goals-left">
          {(window.innerWidth > 1030 || (window.innerWidth <= 950 && window.innerWidth >= 555)) && (
            <Typography className="goals-title h1-title">{t('goals_title')}</Typography>
          )}
          <Typography className="goals-description description-title">
            <Trans t={t} i18nKey="goals_description" />
          </Typography>
        </div>
        <img className="goals-right" src={require('../../assets/images/goal.png')} />
      </div>
      <img className="gradient" src={gradient}></img>
    </div>
  );
};

export default About;
