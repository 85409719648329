import React from 'react';
import Button from '@mui/material/Button';
import 'components/Survey/SurveyButton/SurveyButton.scss';

type ButtonProps = {
  text: string;
  value: string;
  className?: 'primary' | 'secondary' | 'white';
  onClick?: any;
};

const SurveyButton: React.FC<ButtonProps> = props => {
  return (
    <Button color="primary" className={'survey-button ' + props.className} onClick={() => props.onClick(props.value)}>
      {props.text}
    </Button>
  );
};

export default SurveyButton;
