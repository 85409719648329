import React from 'react';
import Button from '@mui/material/Button';
import 'components/Button/Button.scss';

type ButtonProps = {
  text: string;
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  variant: 'text' | 'outlined' | 'contained' | undefined;
  className?: string;
  onClick?: any;
};

const ButtonComponent: React.FC<ButtonProps> = props => {
  return (
    <Button className={'button ' + props.className} color={props.color} variant={props.variant} onClick={props.onClick}>
      {props.text}
    </Button>
  );
};

export default ButtonComponent;
