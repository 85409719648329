import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

type PageHeadProps = PropsWithChildren<{
  title: string;
}>;

const DocumentHead: React.FC<PageHeadProps> = ({ children, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </>
);

export default DocumentHead;
