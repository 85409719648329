import NotFound from 'views/NotFound/NotFound';

export default [
  {
    path: '*',
    element: NotFound,
    name: '404',
    isProtected: false,
  },
];
