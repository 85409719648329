import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { contacts } from 'constants/contacts';
import 'components/Dialog/Dialog.scss';

// import telegramPurple from 'assets/icons/telegram-purple.svg'
// import telegramGrey from 'assets/icons/telegram-grey.svg'

type DialogProps = {
  open: boolean;
  handleClose: any;
};

const About: React.FC<DialogProps> = props => {
  const { t } = useTranslation('popup');

  return (
    <div>
      <Dialog
        className="dialog-popup"
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Typography className="popup-title h1-title">{t('title')}</Typography>
        <Typography className="popup-description description-title">{t('description')}</Typography>
        <div className="socials-container">
          {contacts.links.map((option, index) => {
            return (
              <a className="social-media-link" key={'social' + index} href={option.link} target="_blank" rel="nofollow">
                <div className="social-media-icon-container">
                  <img
                    className={'social-media-icon ' + option.icon + '-icon'}
                    src={require(`../../assets/icons/${option.icon}-white.svg`)}
                  />
                </div>
                <Typography className="social-media-name">{option.name}</Typography>
              </a>
            );
          })}
        </div>
        <div className="contacts-container">
          <Typography className="contacts-title description-title">{t('contacts')}</Typography>
          <div className="contacts">
            <a href={'mailto:' + contacts.email}>
              <Typography className="contacts-text">{contacts.email}</Typography>
            </a>
            <a href={'tel:' + contacts.phone}>
              <Typography className="contacts-text">{contacts.phone}</Typography>
            </a>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default About;
