import React from 'react';
import Typography from '@mui/material/Typography';
import 'components/Services/ServiceCard/ServiceCard.scss';
import { HashLink as Link } from 'react-router-hash-link';

type CardProps = {
  service: {
    title: string;
    description: string;
    list?: Array<string>;
  };
  icon: string;
  isAvailable: boolean;
  link?: string;
};
const arrowIconPath = 'arrow-right.svg';

const ServiceCard: React.FC<CardProps> = props => {
  return (
    <div className={'card-container ' + (props.isAvailable === true ? 'available-card' : 'unavailable-card')}>
      <div className="unavailable-layer"></div>
      <Link to={props.link ? props.link : ''} className="service-link">
        <div>
          <img className="card-icon" src={require(`../../../assets/icons/${props.icon}`)} />
          <Typography className="card-title">{props.service.title}</Typography>
          <Typography className="card-description">{props.service.description}</Typography>
          {props.service.list && (
            <ul className="card-list">
              {props.service.list.map(item => {
                return <li>{item}</li>;
              })}
            </ul>
          )}
        </div>
        <div className="card-link">
          <Typography className="card-link-text">{props.isAvailable ? ' Подробнее' : 'Скоро'}</Typography>
          <img className="arrow-icon" src={require(`../../../assets/icons/${arrowIconPath}`)} />
        </div>
      </Link>
    </div>
  );
};

export default ServiceCard;
