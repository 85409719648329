import React from 'react';
import Tooltip from '@mui/material/Tooltip';

type TooltipProps = {
  text: string;
  term: string;
};

const TooltipText: React.FC<TooltipProps> = props => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <Tooltip title={props.term} disableFocusListener open={open}>
      <div className="tooltip-text" onClick={handleTooltipOpen}>
        <span>{props.text}</span>
        <div className="underline"></div>
      </div>
    </Tooltip>
  );
};

export default TooltipText;
