import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Additional/Additional.scss';
import Button from 'components/Button/Button';
import Dialog from 'components/Dialog/Dialog';
import { additionalServices } from 'constants/mainPage';
import gradient from '../../assets/images/additional-gradient.svg';
import VatToggle from 'components/VatToggle/VatToggle';

const Additional: React.FC = () => {
  const { t } = useTranslation('mainPage');
  const [isNetto, setIsNetto] = React.useState(false);
  const additionalData: Array<{ title: string; description: string }> = t('additional_services', {
    returnObjects: true,
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNettoChange = (value: boolean) => {
    setIsNetto(value);
  };

  return (
    <div className="additional" id="price-list">
      <Typography className="additional-title h1-title">{t('additional_title')}</Typography>
      <VatToggle isNetto={isNetto} handleChange={handleNettoChange} />
      {additionalData.map((additionalOption, index) => {
        return (
          <React.Fragment>
            <div className="additional-option" key={additionalServices[index].id}>
              <div className="additional-option-text">
                <Typography
                  className={
                    additionalOption.description ? 'additional-option-title has-description' : 'additional-option-title'
                  }
                >
                  {additionalOption.title}
                </Typography>
                <Typography className="additional-option-description">{additionalOption.description}</Typography>
              </div>
              <Typography className="additional-option-price">
                {additionalServices[index].prices[0].netto === '?'
                  ? t('additional_unknown_price')
                  : isNetto
                  ? additionalServices[index].prices[0].brutto + ' ' + additionalServices[index].prices[0].currencyName
                  : additionalServices[index].prices[0].netto + ' ' + additionalServices[index].prices[0].currencyName}
              </Typography>
            </div>
            <div className="divider"></div>
          </React.Fragment>
        );
      })}
      <div className="button-container">
        <Button text={t('additional_button')} color="primary" variant="contained" onClick={handleClickOpen}></Button>
      </div>
      <img className="gradient" src={gradient}></img>
      <Dialog open={open} handleClose={handleClose}></Dialog>
    </div>
  );
};

export default Additional;
