import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  let root: HTMLElement;

  useEffect(() => {
    root = document.getElementById('root')!;
    root.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
