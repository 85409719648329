import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Services/Services.scss';
import { services } from 'constants/mainPage';
import ServiceCard from 'components/Services/ServiceCard/ServiceCard';

const Services: React.FC = () => {
  const { t } = useTranslation('mainPage');
  const servicesArray: Array<{ title: string; description: string }> = t('services', { returnObjects: true });

  return (
    <div className="services" id="services">
      <Typography className="services-title h1-title">{t('services_title')}</Typography>
      <Typography className="services_description description-title">{t('services_description')}</Typography>
      <div className="services-cards">
        {servicesArray.map((service, index) => {
          return (
            <ServiceCard
              key={services[index].id}
              service={service}
              icon={services[index].icon}
              isAvailable={services[index].isAvailable}
              link={services[index].link}
            ></ServiceCard>
          );
        })}
      </div>
    </div>
  );
};

export default Services;
