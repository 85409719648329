import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';
import { contacts } from 'constants/contacts';
import { navigation } from 'constants/navigation';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import 'components/Header/Header.scss';
import { HashLink as Link } from 'react-router-hash-link';

const Header: React.FC = () => {
  const { t } = useTranslation('header');
  const navOptions: Array<string> = t('options', { returnObjects: true });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isScrollAtTop, setIsScrollAtTop] = React.useState(true);
  let root: HTMLElement;

  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  const handleScroll = () => {
    setIsScrollAtTop(root.scrollTop === 0);
  };

  const scrollToTopIfPathMatches = () => {
    if (window.location.pathname === '/') {
      root = document.getElementById('root')!;
      root.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    root = document.getElementById('root')!;
    root?.addEventListener('scroll', handleScroll);

    return () => {
      root?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (window.innerWidth < 1164 && window.innerWidth > 855) {
    navOptions.pop();
  }
  return (
    <div className={'header-container ' + (isScrollAtTop ? '' : 'sticky-header')}>
      <div className="header">
        <Link className="nav-option-link" to="/">
          <img className="logo" src={logo} onClick={scrollToTopIfPathMatches} />
        </Link>
        {window.innerWidth > 855 ? (
          <>
            <div className="nav-options">
              {navOptions.map((option, index) => {
                return (
                  <Link className="nav-option-link" to={navigation[index]}>
                    <Typography className="nav-option" key={'nav' + index}>
                      {option}
                    </Typography>
                  </Link>
                );
              })}
            </div>
            <div className="social-media">
              {contacts.links.map((option, index) => {
                return (
                  <a
                    className="social-media-link"
                    key={'social' + index}
                    href={option.link}
                    target="_blank"
                    rel="nofollow"
                  >
                    <div className={'social-media-icon-container ' + option.icon + '-icon'}>
                      <img className="social-media-icon" src={require(`../../assets/icons/${option.icon}-white.svg`)} />
                    </div>
                  </a>
                );
              })}
            </div>
          </>
        ) : (
          <Fab color="primary" aria-label="add" onClick={() => toggleDrawer(true)}>
            <MenuIcon />
          </Fab>
        )}

        <SwipeableDrawer
          anchor="right"
          open={openDrawer}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <div className="drawer-container">
            <div className="nav-options">
              {navOptions.map((option, index) => {
                return (
                  <Link
                    className="nav-option-link"
                    to={navigation[index]}
                    onClick={() => {
                      setOpenDrawer(false);
                    }}
                  >
                    <Typography className="nav-option" key={'nav' + index}>
                      {option}
                    </Typography>
                  </Link>
                );
              })}
            </div>
            <div className="social-media">
              {contacts.links.map((option, index) => {
                return (
                  <a
                    className="social-media-link"
                    key={'social' + index}
                    href={option.link}
                    target="_blank"
                    rel="nofollow"
                  >
                    <img className="social-media-icon" src={require(`../../assets/icons/${option.icon}.svg`)} />
                    <Typography className="social-media-name">{option.name}</Typography>
                  </a>
                );
              })}
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  );
};

export default Header;
