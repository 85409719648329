import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Plans/Plans.scss';
import Plan from 'components/Plans/Plan/Plan';
import Button from 'components/Button/Button';
import { b2bPlans } from 'constants/mainPage';
import gradient from '../../assets/images/b2b-gradient.svg';
import Dialog from 'components/Dialog/Dialog';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

const Plans: React.FC = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { t } = useTranslation('mainPage');
  const plansData: Array<{ title: string; options: Array<string> }> = t('b2b_plans', { returnObjects: true });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="plans" id="b2b">
      <Typography className="plans-title h1-title">{t('b2b_title')}</Typography>
      <Typography className="plans-description description-title">
        <Trans t={t} i18nKey="b2b_description" />
      </Typography>
      {window.innerWidth < 900 ? (
        <div id="b2b-plans">
          <Slider {...settings}>
            {plansData.map((plan, index) => {
              return (
                <Plan
                  id={b2bPlans[index].id}
                  title={t('b2b_plans.' + index + '.title')}
                  key={b2bPlans[index].id}
                  prices={b2bPlans[index].prices}
                  index={index}
                  optionsData={t('b2b_plans.' + index + '.options', { returnObjects: true })}
                ></Plan>
              );
            })}
          </Slider>
        </div>
      ) : (
        <div className="b2b-plans" id="b2b-plans">
          {plansData.map((plan, index) => {
            return (
              <Plan
                id={b2bPlans[index].id}
                title={t('b2b_plans.' + index + '.title')}
                key={b2bPlans[index].id}
                prices={b2bPlans[index].prices}
                index={index}
                optionsData={t('b2b_plans.' + index + '.options', { returnObjects: true })}
              ></Plan>
            );
          })}
        </div>
      )}

      <div className="actions">
        <Link to="/survey">
          <Button text={t('b2b_button')} color="primary" variant="contained"></Button>
        </Link>
        <Typography className="b2b-link" onClick={handleClickOpen}>
          {t('b2b_link')}
        </Typography>
      </div>
      <img className="gradient" src={gradient}></img>
      <Dialog open={open} handleClose={handleClose}></Dialog>
    </div>
  );
};

export default Plans;
