import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import header from './ru/header.json';
import mainPage from './ru/mainPage.json';
import popup from './ru/popup.json';
import terms from './ru/terms.json';
import survey from './ru/survey.json';
import legalization from './ru/legalization.json';

const resources = {
  ru: {
    header,
    mainPage,
    popup,
    terms,
    survey,
    legalization,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ru',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
