import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Legalization/LegalizationDiscount/LegalizationDiscount.scss';
import gradient from '../../../assets/images/about-gradient.svg';

const LegalizationDiscount: React.FC = () => {
  const { t } = useTranslation('legalization');

  return (
    <div className="discount-container">
      <div className="discount">
        <div className="discount-info">
          <Typography className="discount-description h1-title">{t('discount')}</Typography>
        </div>
      </div>
      <img className="gradient" src={gradient}></img>
    </div>
  );
};

export default LegalizationDiscount;
