import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import 'components/Survey/Survey.scss';
import SurveyButton from 'components/Survey/SurveyButton/SurveyButton';
import LinearProgress from '@mui/material/LinearProgress';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type SurveyProps = {
  finishSurvey?: any;
};

const Survey: React.FC<SurveyProps> = props => {
  const { t } = useTranslation('survey');
  const surveyData: Array<{ question: string; answers: Array<{ text: string; value: string }> }> = t('b2b_survey', {
    returnObjects: true,
  });
  const [index, setIndex] = useState(0);
  const [answersArray, setAnswersArray] = useState<string[]>([]);

  useEffect(() => {
    console.log(answersArray);
    console.log(index);
    if (answersArray.length === surveyData.length) {
      props.finishSurvey(answersArray);
    }
  }, [answersArray]);

  const changeIndex = (answer: string) => {
    setAnswersArray([...answersArray, answer]);
    if (index < surveyData.length - 1) {
      if (index === 4 && answer === '+') {
        setAnswersArray([...answersArray, '+', '-', '-']);
        setIndex(index + 3);
      } else {
        setIndex(index + 1);
      }
    }
  };

  const goBack = () => {
    if (index === 7 && answersArray[4] === '+') {
      setIndex(index - 3);
      setAnswersArray(() => answersArray.slice(0, answersArray.length - 3));
    } else {
      setIndex(index - 1);
      setAnswersArray(() => answersArray.slice(0, answersArray.length - 1));
    }
  };

  const getButtonColor = (answerIndex: number) => {
    switch (answerIndex) {
      case 0:
        return 'primary';
        break;
      case 1:
        return 'secondary';
        break;
      case 2:
        return 'white';
        break;
      default:
        return 'primary';
        break;
    }
  };

  return (
    <div className="survey">
      <Fab className={index > 0 ? 'back-button' : 'display-none'} color="primary" aria-label="add" onClick={goBack}>
        <ArrowBackIcon />
      </Fab>
      <LinearProgress value={(index / surveyData.length) * 100} variant="determinate" />
      <Typography className="survey-title h1-title">{surveyData[index].question}</Typography>
      <div className="survey-answers">
        {surveyData[index].answers.map((answer, answerIndex) => {
          return (
            <SurveyButton
              value={answer.value}
              text={answer.text}
              className={getButtonColor(answerIndex)}
              onClick={changeIndex}
            ></SurveyButton>
          );
        })}
      </div>
    </div>
  );
};

export default Survey;
