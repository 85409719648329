import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Legalization/Legalization.scss';
import Button from 'components/Button/Button';
import { Link } from 'react-router-dom';

const Legalization: React.FC = () => {
  const { t } = useTranslation('mainPage');

  return (
    <div className="legalization-container" id="legalization">
      <div className="legalization">
        <Typography className="legalization-title h1-title">
          <Trans t={t} i18nKey="legalization_title" />
        </Typography>
        <Typography className="legalization-description description-title">
          <Trans t={t} i18nKey="legalization_description" />
        </Typography>
        <Link to="/legalization">
          <Button
            className="legalization-button"
            text={t('legalization_button')}
            color="primary"
            variant="outlined"
          ></Button>
        </Link>
      </div>
    </div>
  );
};

export default Legalization;
