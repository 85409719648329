import React from 'react';
import { Route, Routes, RouteProps } from 'react-router-dom';
import ProtectedRoute from 'router/ProtectedRoute';
import PublicRoute from 'router/PublicRoute';

export type PageData = {
  logoSubtitle?: string;
};

export interface IRoute extends RouteProps {
  name: string;
  isProtected?: boolean;
  pageData?: PageData;
}

export type RoutesConfig = {
  routes: IRoute[];
};

export type RoutesRenderer = JSX.Element;
export type RenderedRoute = JSX.Element;

export const mapRoute = ({ isProtected = true, path, ...route }: IRoute): Nullable<RenderedRoute> => (
  <Route key={path} path={path} element={isProtected ? <ProtectedRoute {...route} /> : <PublicRoute {...route} />} />
);

const MappedRoutes = ({ routes }: RoutesConfig): RoutesRenderer => {
  return <Routes>{routes.map(mapRoute)}</Routes>;
};

export default MappedRoutes;
