import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Main/Main.scss';
import Button from 'components/Button/Button';
import gradient from '../../assets/images/main-gradient.svg';
import { HashLink as Link } from 'react-router-hash-link';

const Main: React.FC = () => {
  const { t } = useTranslation('mainPage');

  return (
    <div className="main-container">
      <div className="main">
        <div className="main-left">
          <Typography className="main-title h1-title">{t('title')}</Typography>
          <Typography className="main-description description-title">{t('description')}</Typography>
          {window.innerWidth > 950 && (
            <div className="button-container">
              <div className="button">
                <Link className="nav-option-link" to="/#services">
                  <Button text={t('choose_button')} color="primary" variant="contained"></Button>
                </Link>
              </div>
              {/* <Button text={t('how_it_works')} color="primary" variant="outlined"></Button> */}
            </div>
          )}
        </div>
        <img className="main-right" src={require('../../assets/images/main_page.png')}></img>
      </div>

      {window.innerWidth <= 950 && (
        <div className="button-container">
          <div className="button">
            <Link className="nav-option-link" to="/#services">
              <Button text={t('choose_button')} color="primary" variant="contained"></Button>
            </Link>
          </div>
          <Button className="how-it-works" text={t('how_it_works')} color="primary" variant="outlined"></Button>
        </div>
      )}
      <img className="gradient" src={gradient}></img>
    </div>
  );
};

export default Main;
