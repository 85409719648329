import React from 'react';
import LegalizationPlans from 'components/Legalization/LegalizationPlans/LegalizationPlans';
import LegalizationDiscount from 'components/Legalization/LegalizationDiscount/LegalizationDiscount';
import LegalizationAdditional from 'components/Legalization/LegalizationAdditional/LegalizationAdditional';
import LegalizationFindService from 'components/Legalization/LegalizationFindService/LegalizationFindService';

const LegalizationPage: React.FC = () => {
  return (
    <div>
      <LegalizationPlans></LegalizationPlans>
      <LegalizationDiscount></LegalizationDiscount>
      <LegalizationAdditional></LegalizationAdditional>
      <LegalizationFindService></LegalizationFindService>
    </div>
  );
};

export default LegalizationPage;
