export const legalizationPlans = [
  {
    id: 'start',
    prices: [
      {
        netto: '800',
        brutto: '984',
        currency: 'zloty',
        currencyName: 'zł',
      },
      {
        netto: '170',
        brutto: '209,1',
        currency: 'euro',
        currencyName: '€',
      },
    ],
  },
  {
    id: 'standart',
    prices: [
      {
        netto: '1500',
        brutto: '1845',
        currency: 'zloty',
        currencyName: 'zł',
      },
      {
        netto: '320',
        brutto: '393,6',
        currency: 'euro',
        currencyName: '€',
      },
    ],
  },
  {
    id: 'individual',
    prices: [
      {
        netto: '2000',
        brutto: '2460',
        currency: 'zloty',
        currencyName: 'zł',
      },
      {
        netto: '430',
        brutto: '528,9',
        currency: 'euro',
        currencyName: '€',
      },
    ],
  },
];

export const additionalServices = [
  {
    id: 'opinia',
    prices: [
      {
        netto: '300',
        brutto: '369',
        currency: 'zloty',
        currencyName: 'zł',
      },
    ],
  },
  {
    id: 'oswiadczenie',
    prices: [
      {
        netto: '100',
        brutto: '123',
        currency: 'zloty',
        currencyName: 'zł',
      },
    ],
  },
];
