import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Legalization/LegalizationAdditional/LegalizationAdditional.scss';
import { additionalServices } from 'constants/legalization';
import gradient from '../../../assets/images/additional-gradient.svg';
import VatToggle from '../../VatToggle/VatToggle';

const LegalizationAdditional: React.FC = () => {
  const { t } = useTranslation('legalization');
  const [isNetto, setIsNetto] = React.useState(false);
  const additionalData: Array<{ title: string; description: string }> = t('additional_services', {
    returnObjects: true,
  });

  const handleNettoChange = (value: boolean) => {
    setIsNetto(value);
  };

  return (
    <div className="additional" id="price-list">
      <Typography className="additional-title h1-title">{t('additional_title')}</Typography>
      <Typography className="additional-description description-title">{t('additional_description')}</Typography>
      <VatToggle isNetto={isNetto} handleChange={handleNettoChange} />
      {additionalData.map((additionalOption, index) => {
        return (
          <React.Fragment>
            <div className="additional-option" key={additionalServices[index].id}>
              <div className="additional-option-text">
                <Typography className="additional-option-title">{additionalOption.title}</Typography>
                <Typography className="additional-option-description">{additionalOption.description}</Typography>
              </div>
              <Typography className="additional-option-price">
                {additionalServices[index].prices[0].netto === '?'
                  ? t('additional_unknown_price')
                  : isNetto
                  ? additionalServices[index].prices[0].brutto + ' ' + additionalServices[index].prices[0].currencyName
                  : additionalServices[index].prices[0].netto + ' ' + additionalServices[index].prices[0].currencyName}
              </Typography>
            </div>
            <div className="divider"></div>
          </React.Fragment>
        );
      })}
      <img className="gradient" src={gradient}></img>
    </div>
  );
};

export default LegalizationAdditional;
