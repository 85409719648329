import React, { useEffect } from 'react';
import Main from 'components/Main/Main';
import About from 'components/About/About';
import Services from 'components/Services/Services';
import Legalization from 'components/Legalization/Legalization';
import Plans from 'components/Plans/Plans';
import Additional from 'components/Additional/Additional';

const MainPage: React.FC = () => {
  useEffect(() => {
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split('#');
      if (hashParts.length > 2) {
        const hash = hashParts.slice(-1)[0];
        document.querySelector(`#${hash}`)?.scrollIntoView();
      }
    };
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
  });
  return (
    <React.Fragment>
      <Main></Main>
      <About></About>
      <Services></Services>
      <Legalization></Legalization>
      <Plans></Plans>
      <Additional></Additional>
    </React.Fragment>
  );
};

export default MainPage;
