// libraries
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
// components
import DocumentHead from 'components/DocumentHead/DocumentHead';
import { RenderedRoute, IRoute } from 'router/Routes';
// store
import useStore from 'hooks/useStore';

const PublicRoute = ({ ...props }: IRoute): Nullable<RenderedRoute> => {
  const Element = props.element as React.ElementType;

  const { name, pageData = {} } = props;
  const { pageStore: { setPageData = null } = {} } = useStore();

  useEffect(() => {
    if (setPageData && pageData) {
      setPageData(pageData);
    }
  });

  return (
    <DocumentHead title={name}>
      <Element {...props} />
    </DocumentHead>
  );
};

export default observer(PublicRoute);
