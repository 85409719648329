import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Legalization/LegalizationPlans/LegalizationPlans.scss';
import Plan from 'components/Plans/Plan/Plan';
import Button from 'components/Button/Button';
import { legalizationPlans } from 'constants/legalization';
import gradient from '../../../assets/images/b2b-gradient.svg';
import Dialog from 'components/Dialog/Dialog';
import Slider from 'react-slick';

const LegalizationPlans: React.FC = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { t } = useTranslation('legalization');
  const plansData: Array<{ title: string; options: Array<string> }> = t('legalization_plans', { returnObjects: true });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="plans" id="b2b">
      <Typography className="plans-title h1-title">{t('title')}</Typography>
      {window.innerWidth < 900 ? (
        <div id="b2b-plans">
          <Slider {...settings}>
            {plansData.map((plan, index) => {
              return (
                <Plan
                  id={legalizationPlans[index].id}
                  title={t('legalization_plans.' + index + '.title')}
                  key={legalizationPlans[index].id}
                  prices={legalizationPlans[index].prices}
                  index={index}
                  optionsData={t('legalization_plans.' + index + '.options', { returnObjects: true })}
                ></Plan>
              );
            })}
          </Slider>
        </div>
      ) : (
        <div className="b2b-plans" id="b2b-plans">
          {plansData.map((plan, index: number) => {
            return (
              <Plan
                id={legalizationPlans[index].id}
                title={t('legalization_plans.' + index + '.title')}
                key={legalizationPlans[index].id}
                prices={legalizationPlans[index].prices}
                index={index}
                optionsData={t('legalization_plans.' + index + '.options', { returnObjects: true })}
              ></Plan>
            );
          })}
        </div>
      )}

      <div className="actions">
        <Button text={t('legalization_button')} color="primary" variant="contained" onClick={handleClickOpen}></Button>
      </div>
      <img className="gradient" src={gradient}></img>
      <Dialog open={open} handleClose={handleClose}></Dialog>
    </div>
  );
};

export default LegalizationPlans;
