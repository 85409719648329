import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TooltipMobile from './TooltipMobile/TooltipMobile';
import { useTranslation } from 'react-i18next';
import './Tooltip.scss';

type TooltipProps = {
  textArray: Array<{ isTooltip: boolean; text: string }>;
};

const TooltipText: React.FC<TooltipProps> = props => {
  const { t } = useTranslation(['terms']);

  return (
    <Typography className="plan-option-text">
      {props.textArray.map(element => {
        if (element.isTooltip) {
          const term = 'b2b_terms.' + element.text.toLowerCase();
          return window.innerWidth > 768 ? (
            <Tooltip title={t(term)} disableFocusListener>
              <div className="tooltip-text">
                <span>{element.text}</span>
                <div className="underline"></div>
              </div>
            </Tooltip>
          ) : (
            <TooltipMobile term={t(term)} text={element.text} />
          );
        } else {
          return element.text;
        }
      })}
    </Typography>
  );
};

export default TooltipText;
