import React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import TooltipText from '../Tooltip/Tooltip';
import './VatToggle.scss';
import { useTranslation } from 'react-i18next';

type VatToggleProps = {
  isNetto: boolean;
  handleChange: any;
  className?: string;
};
const AntSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'rgba(0,0,0,.25)',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const VatToogle: React.FC<VatToggleProps> = props => {
  const { t } = useTranslation('mainPage');
  const [state, setState] = React.useState(false);

  const handleToggleChange = (event: any) => {
    setState(event.target.checked);
    props.handleChange(event.target.checked);
  };

  return (
    <div className={'toggle ' + props.className}>
      <Stack direction="row" spacing={1} alignItems="center">
        <TooltipText textArray={[t('netto', { returnObjects: true })]} />
        <AntSwitch
          color="secondary"
          checked={state}
          value={state}
          onChange={handleToggleChange}
          inputProps={{ 'aria-label': 'ant design' }}
        />
        <TooltipText textArray={[t('brutto', { returnObjects: true })]} />
      </Stack>
    </div>
  );
};

export default VatToogle;
