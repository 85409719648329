// libraries
import { makeAutoObservable } from 'mobx';
// stores
import { RootStore } from 'stores/RootStore';
// types
import { PageData } from 'router/Routes';

class PageStore {
  pageData: Nullable<PageData> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setPageData = (data: PageData): void => {
    this.pageData = data;
  };
}

export default PageStore;
