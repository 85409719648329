import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

const fontFallbacks = ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'];

export const palette = {
  aplayerPrimary: {
    light: '#8977ff',
    main: '#4b4aef',
    dark: '0020bb',
    contrastText: '#ffffff',
  },
  aplayerSecondary: {
    light: '#ffc586',
    main: '#ff9458',
    dark: 'c7652c',
    contrastText: '#000000',
  },
};

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', ...fontFallbacks].join(','),
  },
  palette: {
    primary: {
        light: palette.aplayerPrimary.light,
        main: palette.aplayerPrimary.main,
        dark: palette.aplayerPrimary.dark,
        contrastText: palette.aplayerPrimary.contrastText
    },
    secondary: {
        light: palette.aplayerSecondary.light,
        main: palette.aplayerSecondary.main,
        dark: palette.aplayerSecondary.dark,
        contrastText: palette.aplayerSecondary.contrastText
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        color: 'secondary',
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          required: false,
        },
        fullWidth: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'secondary',
      },
    },
  },
});

interface AppThemeProps {
  children: React.ReactNode;
}

const AppTheme: React.FC<AppThemeProps> = ({ children }: AppThemeProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default AppTheme;
