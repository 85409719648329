// libraries
import { create } from 'mobx-persist';
// stores
import PageStore from 'stores/PageStore/PageStore';
import AuthStore from 'stores/AuthStore/AuthStore';
import UserStore from 'stores/UserStore/UserStore';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

class RootStore {
  pageStore: PageStore;

  authStore: AuthStore;

  userStore: UserStore;

  constructor() {
    this.pageStore = new PageStore(this);
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);

    Promise.all([hydrate('auth', this.authStore), hydrate('user', this.userStore)]).then(() => {
      this.authStore.setIsAuthLoading(false);
    });
  }
}

const createStore = (): RootStore => new RootStore();

export { createStore, RootStore };
