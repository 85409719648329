import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Survey/SurveyResult/SurveyResult.scss';
import Plan from 'components/Plans/Plan/Plan';
import Button from 'components/Button/Button';
import Dialog from 'components/Dialog/Dialog';
import { b2bPlans } from 'constants/mainPage';

type ResultProps = {
  result: Array<string>;
};

const SurveyResult: React.FC<ResultProps> = props => {
  const { t } = useTranslation(['mainPage', 'survey']);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getResult = () => {
    console.log(props.result);
    if (props.result[7] === '+' || props.result[8] === '+' || props.result[9] === '+') {
      return (
        <Plan
          id={b2bPlans[2].id}
          title={t('b2b_plans.2.title', { ns: 'mainPage' })}
          key={b2bPlans[2].id}
          prices={b2bPlans[2].prices}
          index={2}
          optionsData={t('b2b_plans.2.options', { ns: 'mainPage', returnObjects: true })}
        ></Plan>
      );
    } else if (
      props.result[0] === '+' ||
      props.result[5] === '-' ||
      props.result[6] === '+' ||
      props.result[4] === '+'
    ) {
      return (
        <Plan
          id={b2bPlans[1].id}
          title={t('b2b_plans.1.title', { ns: 'mainPage' })}
          key={b2bPlans[1].id}
          prices={b2bPlans[1].prices}
          index={1}
          optionsData={t('b2b_plans.1.options', { ns: 'mainPage', returnObjects: true })}
        ></Plan>
      );
    } else if (props.result[1] === '+' || props.result[2] === '+' || props.result[3] === '+') {
      return (
        <div className="two-plans">
          <Plan
            id={b2bPlans[0].id}
            title={t('b2b_plans.0.title', { ns: 'mainPage' })}
            key={b2bPlans[0].id}
            prices={b2bPlans[0].prices}
            index={0}
            optionsData={t('b2b_plans.0.options', { ns: 'mainPage', returnObjects: true })}
          ></Plan>
          <Plan
            id={b2bPlans[1].id}
            title={t('b2b_plans.1.title', { ns: 'mainPage' })}
            key={b2bPlans[1].id}
            prices={b2bPlans[1].prices}
            index={1}
            optionsData={t('b2b_plans.1.options', { ns: 'mainPage', returnObjects: true })}
          ></Plan>
        </div>
      );
    } else {
      return (
        <Plan
          id={b2bPlans[0].id}
          title={t('b2b_plans.0.title', { ns: 'mainPage' })}
          key={b2bPlans[0].id}
          prices={b2bPlans[0].prices}
          index={0}
          optionsData={t('b2b_plans.0.options', { ns: 'mainPage', returnObjects: true })}
        ></Plan>
      );
    }
  };
  return (
    <div className="survey-result">
      <Typography className="result-title h1-title">{t('result', { ns: 'survey' })}</Typography>
      {getResult()}
      <div className="result-button-container">
        <Button
          text={t('result_button', { ns: 'survey' })}
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        ></Button>
      </div>
      <Dialog open={open} handleClose={handleClose}></Dialog>
    </div>
  );
};

export default SurveyResult;
