import React from 'react';
import Survey from 'components/Survey/Survey';
import SurveyResult from 'components/Survey/SurveyResult/SurveyResult';

const SurveyPage: React.FC = () => {
  const [result, setResult] = React.useState([]);

  const handleSurvey = (resultData: any) => {
    setResult(resultData);
  };

  return result.length ? <SurveyResult result={result}></SurveyResult> : <Survey finishSurvey={handleSurvey}></Survey>;
};

export default SurveyPage;
