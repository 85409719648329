import MainPage from '../../views/MainPage/MainPage';
import SurveyPage from '../../views/SurveyPage/SurveyPage';
import LegalizationPage from '../../views/LegalizationPage/LegalizationPage';
import serviceRoutes from 'router/routes/service-routes/service-routes';

export default [
  {
    path: '/',
    element: MainPage,
    name: '"A" Player',
  },
  {
    path: '/survey',
    element: SurveyPage,
    name: '"A" Player',
  },
  {
    path: '/legalization',
    element: LegalizationPage,
    name: '"A" Player',
  },
  ...serviceRoutes,
];
