import React from 'react';
import { HashRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MappedRoutes from 'router/Routes';
import routes from 'router/routes/routes';
import { createStore } from 'stores/RootStore';
import { StoreProvider } from 'contexts/store/storeProvider';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import AppTheme from 'components/App/AppTheme/AppTheme';
import ScrollToTop from '../../hooks/scrollToTop';

const rootStore = createStore();

const App: React.FC = () => (
  <HashRouter>
    <ScrollToTop />
    <StoreProvider value={rootStore}>
      <AppTheme>
        <div className="app-container">
          <Header></Header>
          <MappedRoutes routes={routes} />
          <Footer></Footer>
        </div>
      </AppTheme>
    </StoreProvider>
  </HashRouter>
);

export default observer(App);
