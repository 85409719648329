// libraries
import { makeAutoObservable } from 'mobx';
import { persist } from 'mobx-persist';
// components
import { RootStore } from 'stores/RootStore';

export type UserStoreData = {
  id: string;
};

class UserStore {
  @persist id: Nullable<string> = null;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setUser = ({ id }: UserStoreData): void => {
    this.id = id;
  };
}

export default UserStore;
