import React from 'react';
import Typography from '@mui/material/Typography';
import 'components/Plans/Plan/Plan.scss';
import TooltipText from '../../Tooltip/Tooltip';
import VatToggle from '../../VatToggle/VatToggle';

type PlanProps = {
  id: string;
  prices: Array<{ netto: string; brutto: string; currency: string; currencyName: string }>;
  index: number;
  title: string;
  optionsData: Array<string>;
};
const checkmarkIconPath = 'checkmark.svg';

const Plan: React.FC<PlanProps> = props => {
  const [isNetto, setIsNetto] = React.useState(false);
  let planPrice: string = '';
  props.prices.map((priceObject, index) => {
    let isLastIndex = index == props.prices.length - 1;
    let price = isNetto ? priceObject.brutto : priceObject.netto;
    planPrice += ' ' + price + ' ' + priceObject.currencyName + (isLastIndex ? '' : ' / ');
  });

  const handleNettoChange = (value: boolean) => {
    setIsNetto(value);
  };

  return (
    <div className={'plan ' + props.id}>
      <Typography className="plan-title">{props.title}</Typography>
      <div className="prices">
        <Typography className="price-text">{planPrice}</Typography>
        <VatToggle isNetto={isNetto} handleChange={handleNettoChange} className={props.id} />
      </div>
      <div className="b2b_plans">
        {props.optionsData.map(option => {
          return (
            <div className="plan-option">
              <img className="checkmark-icon" src={require(`../../../assets/icons/${checkmarkIconPath}`)} />
              {typeof option === 'string' ? (
                <Typography className="plan-option-text">{option}</Typography>
              ) : (
                <TooltipText textArray={option} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Plan;
