// libraries
import { makeAutoObservable } from 'mobx';
import { persist } from 'mobx-persist';
// stores
import { RootStore } from 'stores/RootStore';

class AuthStore {
  @persist token: Nullable<string> = null;

  isAuthLoading = true;

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setIsAuthLoading = (value = false): void => {
    this.isAuthLoading = value;
  };
}

export default AuthStore;
