export const contacts = {
  links: [
    {
      name: 'Telegram',
      link: 'https://t.me/Alina_Kur',
      icon: 'telegram',
    },
    {
      name: 'Whatsapp',
      link: 'https://wa.me/48505170707',
      icon: 'whatsapp',
    },
    {
      name: 'Viber',
      link: 'viber://chat/?number=%2B48505170707',
      icon: 'viber',
    },
  ],
  email: 'alina@aplayer.pl',
  phone: '+48505170707',
};
