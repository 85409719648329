export const services = [
  {
    id: 'self_employed',
    isAvailable: true,
    icon: 'briefcase.svg',
    link: '/#b2b',
  },
  {
    id: 'legalization',
    isAvailable: true,
    icon: 'employee_id.svg',
    link: '/#legalization',
  },
  {
    id: 'additional',
    isAvailable: true,
    icon: 'report.svg',
    link: '/#price-list',
  },
  {
    id: 'accounting',
    isAvailable: false,
    icon: 'employee_desk.svg',
  },
  {
    id: 'company',
    isAvailable: false,
    icon: 'business.svg',
  },
];

export const b2bPlans = [
  {
    id: 'start',
    prices: [
      {
        netto: '700',
        brutto: '861',
        currency: 'zloty',
        currencyName: 'zł',
      },
      {
        netto: '150',
        brutto: '184,5',
        currency: 'euro',
        currencyName: '€',
      },
    ],
  },
  {
    id: 'standart',
    prices: [
      {
        netto: '1100',
        brutto: '1353',
        currency: 'zloty',
        currencyName: 'zł',
      },
      {
        netto: '230',
        brutto: '282,9',
        currency: 'euro',
        currencyName: '€',
      },
    ],
  },
  {
    id: 'individual',
    prices: [
      {
        netto: '2000',
        brutto: '2460',
        currency: 'zloty',
        currencyName: 'zł',
      },
      {
        netto: '430',
        brutto: '528,9',
        currency: 'euro',
        currencyName: '€',
      },
    ],
  },
];

export const additionalServices = [
  {
    id: 'pesel',
    prices: [
      {
        netto: '100',
        brutto: '123',
        currency: 'zloty',
        currencyName: 'zł',
      },
    ],
  },
  {
    id: 'documents',
    prices: [
      {
        netto: '100-400',
        brutto: '123-492',
        currency: 'zloty',
        currencyName: 'zł',
      },
    ],
  },
  {
    id: 'cfr',
    prices: [
      {
        netto: '100',
        brutto: '123',
        currency: 'zloty',
        currencyName: 'zł',
      },
    ],
  },
  {
    id: 'office',
    prices: [
      {
        netto: '100',
        brutto: '123',
        currency: 'zloty',
        currencyName: 'zł',
      },
    ],
  },
  {
    id: 'signature',
    prices: [
      {
        netto: '350-500',
        brutto: '430,5-615',
        currency: 'zloty',
        currencyName: 'zł',
      },
    ],
  },
];
