import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import 'components/Legalization/LegalizationFindService/LegalizationFindService.scss';
import Button from 'components/Button/Button';
import Dialog from 'components/Dialog/Dialog';

const LegalizationFindService: React.FC = () => {
  const { t } = useTranslation('legalization');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="find-service">
      <Typography className="find-service-title description-title">{t('find_service')}</Typography>
      <Button text={t('find_service_button')} color="primary" variant="contained" onClick={handleClickOpen}></Button>
      <Dialog open={open} handleClose={handleClose}></Dialog>
    </div>
  );
};

export default LegalizationFindService;
