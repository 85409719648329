// libraries
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
// import { Navigate } from 'react-router-dom';
// components
import DocumentHead from 'components/DocumentHead/DocumentHead';
import { RenderedRoute, IRoute } from 'router/Routes';
// store
import useStore from 'hooks/useStore';

const ProtectedRoute = ({ ...props }: IRoute): Nullable<RenderedRoute> => {
  const Element = props.element as React.ElementType;

  const { name, pageData = {} } = props;
  const {
    pageStore: { setPageData = null } = {},
    // authStore: { token },
  } = useStore();

  useEffect(() => {
    if (setPageData) {
      setPageData(pageData);
    }
  });

  // if (!token) {
  //   return <Navigate to='/login' replace />;
  // }

  return (
    <DocumentHead title={name}>
      <Element {...props} />
    </DocumentHead>
  );
};

export default observer(ProtectedRoute);
