import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';
import { contacts } from 'constants/contacts';
import { navigation } from 'constants/navigation';
import Typography from '@mui/material/Typography';
import 'components/Footer/Footer.scss';
import { HashLink as Link } from 'react-router-hash-link';

const Footer: React.FC = () => {
  const { t } = useTranslation(['mainPage', 'header']);
  const navOptions: Array<string> = t('options', { ns: 'header', returnObjects: true });

  return (
    <div className="footer-container">
      <div className="footer">
        <div className="logo-socials-container">
          <img className="logo" src={logo} />
          <div className="social-media">
            {contacts.links.map((option, index) => {
              return (
                <a
                  className="social-media-link"
                  key={'social' + index}
                  href={option.link}
                  target="_blank"
                  rel="nofollow"
                >
                  <img className="social-media-icon" src={require(`../../assets/icons/${option.icon}.svg`)} />
                </a>
              );
            })}
          </div>
        </div>
        <div className="nav-options">
          {navOptions.map((option, index) => {
            return (
              <Link className="nav-option-link" to={navigation[index]}>
                <Typography className="nav-option" key={'nav' + index}>
                  {option}
                </Typography>
              </Link>
            );
          })}
        </div>
        <div className="contacts-container">
          <a href={'mailto:' + contacts.email} className="email-link">
            <Typography className="contacts-text">{contacts.email}</Typography>
          </a>
          <a href={'tel:' + contacts.phone}>
            <Typography className="contacts-text">{contacts.phone}</Typography>
          </a>
        </div>
      </div>
      <div className="divider"></div>
      <Typography className="rights-text">@a_player All rights reserved</Typography>
    </div>
  );
};

export default Footer;
